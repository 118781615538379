@keyframes pulse {
  0%,
  100% {
    background-color: rgba(0, 0, 0, 0.5);
  }
  50% {
    background-color: rgba(0, 0, 0, 0.6);
  }
}

@keyframes glow {
  0%,
  100% {
    box-shadow: 0 0 5px rgba(255, 165, 0, 0.3);
  }
  50% {
    box-shadow: 0 0 25px rgba(255, 165, 0, 0.7);
  }
}

.loading-animation {
  animation: pulse 5s infinite ease-in-out, glow 5s infinite ease-in-out;
}

.loading-animation.middle {
  animation: pulse 5s infinite ease-in-out, glow 5s infinite ease-in-out;
  animation-delay: 2.5s;
}

.loading-text {
  color: #fff;
  font-size: 16px;
  text-align: center;
  padding: 20px;
}

/* Title animations (reverted to original) */
.blinking-text-1 {
  animation: breathe 5s ease-in-out infinite;
}

.blinking-text-2 {
  animation: breathe 5s ease-in-out infinite 2.5s;
}

@keyframes breathe {
  0%,
  100% {
    color: black;
  }
  50% {
    color: white;
  }
}

@keyframes glow-1 {
  0%,
  100% {
    box-shadow: 0 0 5px 2px rgba(255, 165, 0, 0.3);
  }
  50% {
    box-shadow: 0 0 20px 10px rgba(255, 165, 0, 0.7);
  }
}

@keyframes glow-2 {
  0%,
  100% {
    box-shadow: 0 0 20px 10px rgba(255, 165, 0, 0.7);
  }
  50% {
    box-shadow: 0 0 5px 2px rgba(255, 165, 0, 0.3);
  }
}

@keyframes glow-3 {
  0%,
  100% {
    box-shadow: 0 0 5px 2px rgba(255, 165, 0, 0.3);
  }
  50% {
    box-shadow: 0 0 20px 10px rgba(255, 165, 0, 0.7);
  }
}

.glow-effect-1,
.glow-effect-2,
.glow-effect-3 {
  transition: box-shadow 0.3s ease-in-out;
}

.glow-effect-1 {
  animation: glow-1 4s ease-in-out;
}

.glow-effect-2 {
  animation: glow-2 4s ease-in-out;
}

.glow-effect-3 {
  animation: glow-3 4s ease-in-out;
}

.reduced-glow {
  animation: none !important;
  box-shadow: 0 0 10px 5px rgba(255, 165, 0, 0.3) !important;
}

.grabbed {
  animation: none !important;
  box-shadow: 0 0 20px 10px rgba(255, 165, 0, 0.7) !important;
}
