/* Dark Mode Styles */
body.dark-mode {
  background-color: #1a1b26; /* Tokyo Night dark background */
  color: #a9b1d6; /* Tokyo Night text color */
  transition: background-color 0.3s ease, color 0.3s ease;
}

body.dark-mode .App-header,
body.dark-mode .App-footer {
  background-color: #16161e; /* Slightly darker background for header/footer */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

body.dark-mode .card {
  background-color: #24283b; /* Tokyo Night card background */
  border-color: #414868; /* Tokyo Night border color */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

body.dark-mode .btn-primary {
  background-color: #7aa2f7; /* Tokyo Night blue */
}

body.dark-mode .btn-secondary {
  background-color: #bb9af7; /* Tokyo Night purple */
}

body.dark-mode .email-link {
  color: #7aa2f7; /* Tokyo Night blue */
  font-weight: 500;
}

body.dark-mode .language-switcher button {
  color: #a9b1d6; /* Tokyo Night text color */
}

/* Dark mode scrollbar */
body.dark-mode::-webkit-scrollbar {
  width: 10px;
}

body.dark-mode::-webkit-scrollbar-track {
  background: #16161e;
}

body.dark-mode::-webkit-scrollbar-thumb {
  background: #414868;
  border-radius: 5px;
}

body.dark-mode::-webkit-scrollbar-thumb:hover {
  background: #565f89;
}

/* Dark mode hero content */
body.dark-mode .hero-content {
  background-color: #1a1b26; /* Match the body background color */
  border: 1px solid #414868;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

/* Dark mode blog post cards */
body.dark-mode .blog-post-card {
  background-color: #24283b;
  border: 1px solid #414868;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Dark mode project cards */
body.dark-mode .project-card {
  background-color: #24283b;
  border: 1px solid #414868;
}

/* Dark mode footer */
body.dark-mode .App-footer {
  border-top: 1px solid #414868;
  color: #c0caf5; /* Brighter text for better contrast */
  font-weight: 500;
}

/* Text sections under banner in dark mode */
body.dark-mode .content p,
body.dark-mode .hero-content p {
  background-color: transparent; /* Ensure text sections have the same background as their container */
  color: #c0caf5; /* Brighter text for better contrast */
  line-height: 1.6;
}

/* Language Switcher Styles */
.language-switcher button:hover {
  background-color: rgba(75, 85, 99, 0.3); /* Light blue hover */
}

/* Theme Toggle Animation */
.theme-toggle-icon {
  transition: transform 0.3s ease-in-out;
}

.theme-toggle-icon:hover {
  transform: rotate(30deg);
}

/* Keep orange gradient in dark mode for banner */
body.dark-mode .gradient-text {
  background: linear-gradient(
    90deg,
    #ff7e5f,
    #feb47b
  ); /* Original orange gradient */
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: background 0.3s ease;
}

/* Hover effect for gradient text in dark mode */
body.dark-mode .gradient-text:hover {
  background: linear-gradient(
    90deg,
    #feb47b,
    #ff7e5f
  ); /* Reversed orange gradient on hover */
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
