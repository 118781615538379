/* Tokyo Night Inspired Light Mode */

/* Base styles for light mode (default) */
body {
  background-color: #f0f2f6; /* Soft light blue-gray background */
  color: #3b4252; /* Dark blue-gray text for contrast */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.App-header,
.App-footer {
  background-color: #e5e9f0; /* Lighter background for header/footer */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: background-color 0.3s ease;
}

.card {
  background-color: #ffffff; /* White card background */
  border: 1px solid #e1e5ee; /* Subtle border */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
}

.btn-primary {
  background-color: #7aa2f7; /* Tokyo Night blue */
  color: white;
  border: none;
  transition: background-color 0.2s ease;
}

.btn-primary:hover {
  background-color: #5d87e0; /* Darker blue on hover */
}

.btn-secondary {
  background-color: #bb9af7; /* Tokyo Night purple */
  color: white;
  border: none;
  transition: background-color 0.2s ease;
}

.btn-secondary:hover {
  background-color: #9d7fe0; /* Darker purple on hover */
}

.email-link {
  color: #5d87e0; /* Darker blue for better contrast */
  font-weight: 500;
  transition: color 0.2s ease;
}

.email-link:hover {
  color: #3b4c7a; /* Even darker blue on hover for better contrast */
  text-decoration: underline;
}

/* Gradient text with Tokyo Night colors in light mode */
.gradient-text {
  background: linear-gradient(
    90deg,
    #7aa2f7,
    #bb9af7
  ); /* Tokyo Night blue to purple gradient */
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: background 0.3s ease;
}

/* Hover effect for gradient text in light mode */
.gradient-text:hover {
  background: linear-gradient(
    90deg,
    #5d87e0,
    #9d7fe0
  ); /* Darker violet-blue gradient on hover */
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Navigation links */
.email-link.mx-2 {
  color: #3b4252; /* Dark text for navigation */
  transition: color 0.2s ease;
}

.email-link.mx-2:hover {
  color: #7aa2f7; /* Tokyo Night blue on hover */
}

.email-link.mx-2.font-bold {
  color: #7aa2f7; /* Tokyo Night blue for active links */
}

/* Language switcher in light mode */
.language-switcher button {
  color: #3b4252;
}

.language-switcher button:hover {
  background-color: rgba(122, 162, 247, 0.1); /* Light blue hover */
}

/* Mobile menu in light mode */
.mobile-menu {
  background-color: #e5e9f0;
}

/* Override dark mode styles when not in dark mode */
body:not(.dark-mode) .theme-toggle-icon.text-gray-300 {
  color: #3b4252; /* Dark color for moon icon in light mode */
}

/* Additional Tokyo Night inspired styles */
.hero-content {
  background-color: #f0f2f6; /* Match the body background color */
  border: 1px solid #e1e5ee;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

/* Custom scrollbar for light mode */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #e5e9f0;
}

::-webkit-scrollbar-thumb {
  background: #7aa2f7;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #5d87e0;
}

/* Logo styles for light mode */
.logo {
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
  transition: filter 0.3s ease, transform 0.3s ease;
}

.logo:hover {
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.15));
  transform: scale(1.05);
}

/* Blog post cards in light mode */
.blog-post-card {
  background-color: #ffffff;
  border: 1px solid #e1e5ee;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.blog-post-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
}

/* Project cards in light mode */
.project-card {
  background-color: #ffffff;
  border: 1px solid #e1e5ee;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.project-card:hover {
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
}

/* Footer in light mode */
.App-footer {
  border-top: 1px solid #e1e5ee;
  background-color: #d8dee9; /* Slightly darker background for better contrast */
  color: #2e3440; /* Darker text for better contrast */
  font-weight: 500;
}

/* Text sections under banner */
.content p,
.hero-content p {
  background-color: transparent; /* Ensure text sections have the same background as their container */
  color: #2e3440; /* Darker text for better contrast */
  line-height: 1.6;
}
