@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

/* Base styles - these will be overridden by lightMode.css and darkMode.css */
body {
  font-family: "Inter", sans-serif;
  /* Default styles removed - now controlled by theme CSS files */
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

html,
body,
#root {
  height: 100%;
}

.App {
  text-align: center;
}

/* Sticky footer styles */
.flex-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.flex-content {
  flex: 1 0 auto; /* This will make content grow to push footer down */
}

.App-footer {
  flex-shrink: 0; /* Prevent footer from shrinking */
  padding: 1rem;
  width: 100%;
}

.App-header {
  padding: 0.5rem; /* Reduce padding to decrease spacing */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero-content {
  max-width: 800px;
  margin: 1rem auto; /* Reduce margin to decrease spacing */
  padding: 1rem;
  background-size: cover;
  border-radius: 8px;
}

.card {
  padding: 1.5rem;
  margin: 1rem;
  border-radius: 8px;
  background-size: cover;
}

/* Content sections */
.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.content p,
.hero-content p {
  margin-bottom: 1.5rem;
}

/* Theme Toggle Animation */
.theme-toggle-icon {
  /* Styles moved to darkMode.css */
}

.theme-toggle-icon:hover {
  /* Styles moved to darkMode.css */
}

/* Base gradient text styles - theme-specific styles in lightMode.css and darkMode.css */
.gradient-text {
  -webkit-background-clip: text; /* Vendor prefix for Safari */
  background-clip: text; /* Standard property for compatibility */
  -webkit-text-fill-color: transparent;
  background-size: 200% 200%; /* For gradient animation */
}

.App-link {
  background: linear-gradient(90deg, #feb47b, #ff7e5f); /* Reversed gradient */
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
}

.btn-primary,
.btn-secondary {
  background: linear-gradient(90deg, #feb47b, #ff7e5f);
  color: #282c34;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.3s ease, filter 0.3s ease;
}

.btn-primary:hover,
.btn-secondary:hover {
  transform: scale(1.03);
  filter: brightness(0.9);
}

.btn-primary {
  background: linear-gradient(90deg, #feb47b, #ff7e5f); /* Reversed gradient */
  color: #282c34;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-secondary {
  background: linear-gradient(90deg, #feb47b, #ff7e5f); /* Reversed gradient */
  color: #282c34;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.full-width-image {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content {
  padding: 2rem;
  border-radius: 8px;
  max-width: 800px;
  margin: auto;
}

.blinking-text {
  animation: breathe 5s ease-in-out infinite;
}

@keyframes breathe {
  0%,
  100% {
    color: #ffffff; /* White */
  }
  50% {
    color: #000000; /* Black */
  }
}

.blinking-text-1 {
  animation: breathe1 4s ease-in-out infinite;
  animation-delay: 0s; /* Ensure all animations start at the same time */
}

.blinking-text-2 {
  animation: breathe2 4s ease-in-out infinite;
  animation-delay: 0s; /* Ensure all animations start at the same time */
}

@keyframes breathe1 {
  0%,
  100% {
    color: #ffffff; /* White */
  }
  50% {
    color: #000000; /* Black */
  }
}

@keyframes breathe2 {
  0%,
  100% {
    color: #000000; /* Black */
  }
  50% {
    color: #ffffff; /* White */
  }
}

.logo {
  font-weight: bold;
  font-size: 2rem; /* Adjust size as needed */
  transition: transform 0.6s ease-in-out, color 0.6s ease-in-out;
  color: #ffffff; /* Initial color */
  font-family: "Inter", sans-serif;
}

.logo:hover {
  transform: scale(1.3); /* Increase size by 30% */
  animation: gradientBlink 2s infinite;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Add this at the end of the file */
canvas {
  touch-action: none;
}

.three-viewer-container {
  width: 100%;
  height: 100%;
}

canvas {
  width: 100% !important;
  height: 100% !important;
  touch-action: none;
}

.blog-reel-container {
  width: 100%;
  overflow-x: hidden;
  padding: 20px 0;
  position: relative;
}

.blog-reel {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  padding-bottom: 20px; /* Add some padding to show the scrollbar */
}

.blog-reel::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit browsers */
}

.blog-post-card {
  flex: 0 0 auto;
  width: calc(33.333% - 20px);
  min-width: 250px;
  max-width: 300px;
  margin-right: 20px;
  scroll-snap-align: start;
  border-radius: 8px;
  overflow: hidden;
  height: 200px; /* Reduced height */
  display: flex;
  flex-direction: column;
}

.blog-post-card img {
  width: 100%;
  height: 100px; /* Reduced image height */
  object-fit: cover;
}

.blog-post-card .p-3 {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.blog-post-card h3 {
  font-size: 1rem;
  line-height: 1.2;
  margin-bottom: 0.25rem;
}

.blog-post-card p {
  font-size: 0.75rem;
  line-height: 1.2;
}

.blog-post-card .line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-clamp: 2; /* Add this line for standard property */
}

/* Show part of the fourth card */
.blog-reel::after {
  content: "";
  flex: 0 0 calc(16.666% - 10px);
  min-width: 125px; /* Half of the minimum width of a card */
}

.scroll-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 10;
}

.scroll-arrow:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.scroll-arrow.left {
  left: 10px;
}

.scroll-arrow.right {
  right: 10px;
}

.scroll-arrow svg {
  width: 24px;
  height: 24px;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.scroll-arrow:hover svg {
  opacity: 1;
}

/* Blog Post Modal Styles */
.blog-post-modal {
  width: 90%;
  max-width: 900px;
  max-height: 90vh;
  overflow-y: auto;
  background-color: #ffffff;
  color: #000000;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.blog-post-modal::-webkit-scrollbar {
  width: 8px;
}

.blog-post-modal::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.blog-post-modal::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.blog-post-modal::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.blog-post-modal .prose {
  max-width: none;
  color: #333;
}

.blog-post-modal .prose img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin: 1.5rem 0;
}

.blog-post-modal .prose h1,
.blog-post-modal .prose h2,
.blog-post-modal .prose h3,
.blog-post-modal .prose h4,
.blog-post-modal .prose h5,
.blog-post-modal .prose h6 {
  color: #1a1a1a;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.blog-post-modal .prose p {
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.blog-post-modal .prose a {
  color: #3182ce;
  text-decoration: none;
  transition: color 0.2s ease;
}

.blog-post-modal .prose a:hover {
  color: #2c5282;
  text-decoration: underline;
}

.blog-post-modal .prose ul,
.blog-post-modal .prose ol {
  margin-left: 1.5rem;
  margin-bottom: 1.5rem;
}

.blog-post-modal .prose li {
  margin-bottom: 0.5rem;
}

.blog-post-modal .prose blockquote {
  border-left: 4px solid #3182ce;
  padding-left: 1rem;
  margin-left: 0;
  color: #4a5568;
  font-style: italic;
}

.blog-post-modal .prose code {
  background-color: #f7fafc;
  padding: 0.2rem 0.4rem;
  border-radius: 4px;
  font-size: 0.875em;
}

.blog-post-modal .prose pre {
  background-color: #2d3748;
  color: #e2e8f0;
  padding: 1rem;
  border-radius: 8px;
  overflow-x: auto;
}

.blog-post-modal .prose pre code {
  background-color: transparent;
  padding: 0;
  color: inherit;
}

.email-link {
  color: #ffffff;
  text-decoration: none;
  transition: background 0.3s ease, color 0.3s ease;
  background: linear-gradient(90deg, #ff7e5f, #feb47b);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.email-link:hover {
  background: linear-gradient(90deg, #feb47b, #ff7e5f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Navigation styles */
nav {
  width: 100%;
  transition: all 0.3s ease;
}

.mobile-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 40;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .hero-content {
    padding: 1rem;
    text-align: center;
  }

  .hero-content h2 {
    font-size: 1.8rem;
    line-height: 1.3;
  }

  .content {
    padding: 1rem;
    margin: 1rem;
  }

  .blog-post-card {
    width: 100%;
  }

  .card {
    width: 100% !important;
    margin: 1rem 0 !important;
  }

  .flex {
    flex-wrap: wrap;
  }

  .three-viewer-container {
    margin: 0.5rem !important;
  }

  /* Hide side viewers on mobile */
  .three-viewer-container:first-child,
  .three-viewer-container:last-child {
    display: none;
  }

  /* Center the middle viewer */
  .three-viewer-container:nth-child(2) {
    width: 280px !important;
    height: 280px !important;
    margin: 0 auto !important;
  }

  /* About page responsive */
  .about-content .flex {
    flex-direction: column;
    align-items: center;
  }

  .about-content .flex > div {
    width: 100% !important;
    margin: 1rem 0;
  }

  /* Solutions page responsive */
  .solutions-content .flex {
    flex-direction: column;
  }

  .solutions-content .card {
    width: 100% !important;
    margin: 1rem 0;
  }

  /* Blog responsive */
  .blog-reel {
    scroll-snap-type: x mandatory;
  }

  .blog-reel > div {
    scroll-snap-align: start;
  }

  /* Footer responsive */
  .App-footer {
    padding: 1rem;
    text-align: center;
  }

  /* Mobile menu styles */
  .mobile-menu {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease;
  }

  .mobile-menu.hidden {
    transform: translateY(-10px);
    opacity: 0;
    visibility: hidden;
  }

  .mobile-menu .email-link {
    display: block;
    padding: 0.75rem 1rem;
    width: 100%;
    text-align: left;
    transition: background-color 0.3s ease;
  }

  .mobile-menu .email-link:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .mobile-menu .email-link.active {
    background-color: rgba(255, 255, 255, 0.15);
  }
}

/* Hero Section Styles */
.hero-section {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  background-size: cover;
  background-position: center;
}

.hero-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.5)
  );
  z-index: 1;
}

.hero-section > * {
  position: relative;
  z-index: 2;
}

/* Project Cards Styles */
.project-card {
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transform: translateY(0);
  position: relative;
  perspective: 1000px;
}

.project-card.expanded {
  opacity: 1;
  transform: translateY(0);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.7);
}

.project-card.collapsed {
  max-height: 10rem;
  opacity: 0.95;
}

.project-card.collapsed:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 28px rgba(0, 0, 0, 0.7);
  border-color: rgba(255, 126, 95, 0.3);
}

.project-card h3 {
  transition: all 0.2s ease;
  color: #ffffff;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.8);
}

.project-card.collapsed:hover h3 {
  transform: translateX(5px);
  color: #feb47b;
}

.project-card a {
  position: relative;
  z-index: 10;
  color: #feb47b;
}

.project-card a:hover {
  color: #ff7e5f;
}

.project-card button {
  transition: all 0.2s ease;
}

.project-card button:hover {
  background-color: rgba(0, 0, 0, 0.3) !important;
  transform: scale(1.1);
}

/* Project card image styles */
.project-card-image {
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  backface-visibility: hidden;
}

.project-card-image.expanded {
  transform: scale(1.1) translateZ(20px);
  opacity: 0.2;
}

.project-card-image.collapsed {
  transform: scale(1) translateZ(0);
  opacity: 0.1;
}

.project-card:hover .project-card-image.collapsed {
  opacity: 0.15;
  transform: scale(1.05) translateZ(10px);
}

/* Featured image styles */
.project-featured-image {
  transform-style: preserve-3d;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  animation: fadeInUp 0.5s ease-out forwards;
}

.project-featured-image:hover {
  transform: scale(1.02) translateZ(30px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.7);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Enhanced project card styles */
.project-card {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.project-card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
  z-index: -1;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.project-card:hover::after {
  opacity: 0.7;
}

.project-card.expanded {
  animation: cardExpand 0.5s ease forwards;
}

@keyframes cardExpand {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}

/* 3D card flip effect */
.project-card.collapsed:hover {
  transform: translateY(-5px) rotateX(2deg) rotateY(2deg);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.project-card.expanded {
  transform: rotateX(0) rotateY(0);
}

/* Category filter styles - moved below cards */
.category-filter-fallback {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  flex-wrap: wrap;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 12px;
  box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.3);
  margin: 0 1rem;
}

.category-button {
  padding: 0.5rem 1.5rem;
  border-radius: 9999px;
  transition: all 0.2s ease-in-out;
  font-size: 0.875rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.category-button.active {
  background: linear-gradient(90deg, #ff7e5f, #feb47b);
  color: #000;
  font-weight: bold;
  transform: scale(1.05);
}

.category-button:not(.active) {
  background-color: #1a1a1a;
  color: #e0e0e0;
}

.category-button:not(.active):hover {
  background-color: #333;
  transform: scale(1.05);
}

/* Scrollable project container */
.overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 126, 95, 0.5) rgba(0, 0, 0, 0.2);
}

.overflow-y-auto::-webkit-scrollbar {
  width: 8px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background: rgba(255, 126, 95, 0.5);
  border-radius: 10px;
}

.overflow-y-auto::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 126, 95, 0.7);
}

/* 3D Tech Stack Visualization */
.tech-stack-3d {
  position: relative;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.2)
  );
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.5), 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  height: 300px;
}

.tech-stack-3d:hover {
  box-shadow: inset 0 0 25px rgba(255, 126, 95, 0.2),
    0 8px 20px rgba(0, 0, 0, 0.4);
  transform: translateY(-5px);
}

/* Add a subtle glow effect to the canvas */
.tech-stack-3d canvas {
  filter: drop-shadow(0 0 5px rgba(255, 126, 95, 0.2));
}

/* Responsive adjustments for project cards */
@media screen and (max-width: 768px) {
  .project-card {
    min-height: auto !important;
  }

  .project-card.collapsed {
    max-height: 10rem;
  }

  .tech-stack-3d {
    height: 250px !important;
  }

  .hero-section {
    padding: 1rem;
  }
}

/* Remove the language switcher styles that we've moved to darkMode.css */
.theme-toggle-icon {
  /* Styles moved to darkMode.css */
}

.theme-toggle-icon:hover {
  /* Styles moved to darkMode.css */
}
